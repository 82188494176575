@import "./variable.scss";

.container {
 padding: 20px;
  display: flex;
 flex-direction: column;
 align-items: center;
}

.act_sobe{
   margin-top: 5px;
   margin-bottom: 5px;
}
.nsl {
   text-align: center;
}

.konfete{
   position: fixed;
   z-index: 1;
}
header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.card {
   box-shadow: 0 3px 10px rgba(200, 200, 200, 0.9);
   border-radius: 10px;
   position: relative;
   overflow: hidden;
   transform-style: preserve-3d;
}

.front, .back, .front1,.front2,.front3,.front4,.front5,.front6,.front7{
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: absolute;
   backface-visibility: hidden;
   transition: transform 0.5s;
}
.front {
   background-image: url(../../slike/help.png);
   background-position: center;
   background-repeat: no-repeat;
   background-color: black;
   background-size: cover;
}
.front1 {
   background-image: url(../../slike/card1.png);
   background-position: center;
   background-repeat: no-repeat;
   background-color: black;
   background-size: cover;
}
.front2 {
   background-image: url(../../slike/card2.jpg);
   background-position: center;
   background-repeat: no-repeat;
   background-color: black;
   background-size: cover;
}
.front3 {
   background-image: url(../../slike/card3.jpg);
   background-position: center;
   background-repeat: no-repeat;
   background-color: black;
   background-size: cover;
}
.front4 {
   background-image: url(../../slike/card4.jpg);
   background-position: center;
   background-repeat: no-repeat;
   background-color: black;
   background-size: cover;
}
.front5 {
   background-image: url(../../slike/card5.jpg);
   background-position: center;
   background-repeat: no-repeat;
   background-color: black;
   background-size: cover;
}
.front6 {
   background-image: url(../../slike/card6.jpg);
   background-position: center;
   background-repeat: no-repeat;
   background-color: black;
   background-size: cover;
}
.front7 {
   background-image: url(../../slike/card7.jpg);
   background-position: center;
   background-repeat: no-repeat;
   background-color: black;
   background-size: cover;
}
.back{
   padding: 2px;
}
.rotated {
   transform: rotateY(180deg);
   //transition-duration: 5s;
}
#uvod{
   position: fixed;
   width: 100%;
   height: 100%;
   z-index: -1;
}













.online_podesavanje{
   background-color: cyan;
   padding: 10px;
   border-radius: 10px;
   //text-align: center;
   //position: relative;
   //width: 32%!important;
}
.allOnline{
   //background-color: red;
   height: 49%;
   padding-top: 10px;
   padding-bottom: 10px;
   padding-left: 10px;
   overflow-y: auto;
   border: 1px solid black;
   border-radius: 10px;
}
.nemaOnlMeca{
   animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
   50% {
     opacity: 0;
   }
 }


 .box{  //prostor izmedju dve bootstrap kolone
    box-sizing: border-box;
    border-right: 4px solid transparent;
    background-clip:padding-box;
 }
 .box1{  //prostor izmedju dve bootstrap kolone
   box-sizing: border-box;
   border-left: 4px solid transparent;
   background-clip:padding-box;
}


.select-kanali{
   border: 1px solid black !important;
}
.start_podesavanje{
   background-color: cyan;
   padding: 10px;
   border-radius: 10px;
   text-align: center;
  // margin-right: 10px;
}
.rez_tab{
   padding-left: 0px !important;
   padding-right: 0px !important;
   padding-bottom: 15px;
   background-color: cyan; 
   border-radius: 10px;
   margin-top: 8px !important;
   padding-top: 10px;
   text-align: center;
}
table{
  
 width: 100%;
 
 
}
th{
   text-align: center !important;
}

td{
   text-align: center;
}
select{
   text-align: center;
}

.watch{
text-align: center;
background-color: #111827;
width: 250px;
height: 50px;
border-radius: 1rem;
}
.time{
    font-size: 2rem;
  margin: 0 auto;
   color: white;
}
input[type=text] {
 width: $proc_100;
 height: 45px;
 border-radius: 5px;
 padding: 3px 0px 3px 3px;
 margin: 5px 1px 3px 0px;
 border: 1px solid gray;
}
input[type=text]:focus {
 box-shadow: 0 0 5px rgba(81, 203, 238, 1);
 padding: 3px 0px 3px 3px;
 margin: 5px 1px 3px 0px;
 border: 1px solid rgba(81, 203, 238, 1);
 outline: $none;
}
input[type=password] {
 width: $proc_100;
 height: 45px;
 border-radius: 5px;
 padding: 3px 0px 3px 3px;
 margin: 5px 1px 3px 0px;
 border: 1px solid gray;
}
input[type=password]:focus {
 box-shadow: 0 0 5px rgba(81, 203, 238, 1);
 padding: 3px 0px 3px 3px;
 margin: 5px 1px 3px 0px;
 border: 1px solid rgba(81, 203, 238, 1);
 outline: $none;
}





/*telefon*/
@media only screen and (max-width: 600px) {
   .klijenat_izm{
      padding-left: 0px!important;
      padding-right: 0px!important;
   }
   .klijenat_izm1{
      padding-left: 20px!important;
      padding-right: 20px!important;
   }
   .slike{
   width: 86px;
   height: 86px;
   }

   .watch{
      width: 125px;;
   }
   
   .PrikazIgraca{
      margin: 0px !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
   }
   .redGlavni{
      width: inherit;
   }
   .nsl {
      display: none;
   }
   .online_podesavanje{
      //width: 100%!important;
      margin-top: 10px !important;
      height: max-content;
   }

}