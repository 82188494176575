@import "./variable.scss";
@import "rsuite/dist/rsuite.css";

.rs-progress-line.rs-progress-line-vertical .rs-progress-line-inner{
    width: 80px!important;
}

html,body,#root{
    height:100%;
}
.pozadina{
    background-image: url("../../../slike/pozadina.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}
.userLevi{
    background-color: rgba(128,128,128, .8);
    padding: 10px;
}
.imeUserCrveni{
    text-align: center;
    margin-top: 15px;
    background-color: red;
    color:white;
    border-radius: 15px;
}
.imeUserPlavi{
    text-align: center;
    margin-top: 15px;
    background-color: blue;
    color:white;
    border-radius: 15px;
}
.progresBarStyle{
    transform: rotate(90deg);
     height: 41px;
    // position: relative;
    // top: 117px;
    // left: -86px;
     width: 272px;
    
}
.progresBar{
    display: flex;
    align-items: center;
    //justify-content: space-between;
}
.userSlike{
    margin: 0px auto;
    width: 200px;
    height: 200px;
}

.pitanjeBrOkolo{
//background-color: blue;
background-color: rgba(128,128,128, .8);

}

.pitanjeBr{
text-align: center;
//background-color: blue;
background-color: rgba(128,128,128, .8);
}
.brPitanje{
    background-color: white;
    padding: 5px;
    margin: 10px;
    border-radius: 40px;
    font-size: 35px;
}

.pitanjeOkolo{
    background-color: rgba(128,128,128, .8);
}
.skalaBrojeva{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(128,128,128, .8);
 }
.pitanjeDiv{
    font-size: 27px;
    // background-color: yellowgreen;
    background-color: rgba(0,0,0, .8);
    color: white;
    min-height: 130px;
    display: grid;
    align-items: center;
    text-align: center;
    padding: 15px;
}

.odgovorJedan{
    background: rgba(128,128,128, .8);
    color: black;
    border: 1px solid #000;
    display: grid;
    align-items: center;
    text-align: center;
    height: 80px;
    transform: skewX(45deg);
    padding: 10px;
}
.odgovorJedanText{
    transform: skewX(-45deg); 
    font-size: 25px;
}
.odgovorDva{
    background-color: rgba(128,128,128, .8);
    color: black;
    border: 1px solid #000;
    display: grid;
    align-items: center;
    text-align: center;
    height: 80px;
    transform: skewX(-45deg);
    padding: 10px;
}
.odgovorDvaText{
    transform: skewX(45deg); 
    font-size: 25px;
}
.odgovorTri{
    background-color:rgba(128,128,128, .8);
    color: black;
    border: 1px solid #000;
    display: grid;
    align-items: center;
    text-align: center;
    height: 80px;
    transform: skewX(-45deg);
    padding: 10px;
}
.odgovorTriText{
    transform: skewX(45deg); 
    font-size: 25px;
}
.odgovorCetiri{
    background-color:rgba(128,128,128, .8);
    color: black;
    border: 1px solid #000;
    display: grid;
    align-items: center;
    text-align: center;
    height: 80px;
    transform: skewX(45deg);
    padding: 10px;
}
.odgovorCetirtText{
    transform: skewX(-45deg); 
    font-size: 25px;
}



.dodPitDiv{
   // width: 100%;
    text-align: center;
}



// .blink_border {
//     border: 1px #ff0000 solid;
    
//     animation: blink 1s;
//     animation-iteration-count: 3;
//  }
 
//  @keyframes blink { 50% { border-color:#fff ; }  }























/*telefon*/
@media only screen and (max-width: 600px) {
    .rs-progress-line.rs-progress-line-vertical .rs-progress-line-inner{
        width: 50px!important;
    }
    .userSlike{
        margin: 0px auto;
        width: 80px;
        height: 80px;
    }
    .progresBarStyle{
        transform: rotate(90deg);
        height: 27px;
        // position: relative;
        // top: 62px;
        // left: -56px;
        width: 150px;
    }
    // .container{
    //     padding: 7px!important;
    // }
    .pitanjeDiv{
        font-size: 19px;
        background-color: rgba(128,128,128, .9);
        color: white;
        min-height: 130px;
        display: grid;
        align-items: center;
        text-align: center;
        padding: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 40px;
    }

    .odgovorJedan{
        background-color: rgba(128,128,128, .8);
        border: 1px solid #000;
        color: black;
        display: grid;
        align-items: center;
        text-align: center;
        height: 70px;
        transform: skewX(0deg);
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 40px;
    }
    .odgovorJedanText{
        transform: skewX(0deg); 
        font-size: 17px;
    }
    .odgovorDva{
        background-color: rgba(128,128,128, .8);
        border: 1px solid #000;
        color: black;
        display: grid;
        align-items: center;
        text-align: center;
        height: 70px;
        transform: skewX(0deg);
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 40px;
    }
    .odgovorDvaText{
        transform: skewX(0deg); 
        font-size: 17px;
    }
    .odgovorTri{
        background-color: rgba(128,128,128, .8);
        border: 1px solid #000;
        color: black;
        display: grid;
        align-items: center;
        text-align: center;
        height: 70px;
        transform: skewX(0deg);
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 40px;
    }
    .odgovorTriText{
        transform: skewX(0deg); 
        font-size: 17px;
    }
    .odgovorCetiri{
        background-color: rgba(128,128,128, .8);
        border: 1px solid #000;
        color: black;
        display: grid;
        align-items: center;
        text-align: center;
        height: 70px;
        transform: skewX(0deg);
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 40px;
    }
    .odgovorCetirtText{
        transform: skewX(0deg); 
        font-size: 17px;
    }
    .brPitanje{
        background-color: white;
        padding: 5px;
        margin: 10px;
        border-radius: 40px;
        font-size: 20px;
    }
    .pitanjeBrOkoloLevo{
      //  border-bottom-left-radius: 40px 40px;
    }
    .pitanjeBrOkoloDesno{
      //  border-bottom-right-radius: 40px 40px;
    }
    .skalaBrojeva{
        padding-top: 0px;
        border-bottom-left-radius: 40px 40px;
        border-bottom-right-radius: 40px 40px;
    }
    .userLeviTop{
        border-top-left-radius: 40px 40px;
        //border-top-right-radius: 40px 40px;
    }
    .userDesniTop{
        
        border-top-right-radius: 40px 40px;
        //border-top-left-radius: 40px 40px;
    }
    .pozadina{
        height: max-content;
    }
    .pozadina1{
        padding-bottom: 0px!important;
    }
}
