.game8green {
    display: grid;
    grid-template-columns: repeat(4, 160px);
    grid-template-rows: repeat(4, 160px);
    grid-gap: 10px;
    background-color: limegreen;
    border: 10px solid limegreen;
    width: max-content;
 }

 .game16green {
    display: grid;
    grid-template-columns: repeat(8, 160px);
    grid-template-rows: repeat(4, 160px);
    grid-gap: 10px;
    background-color: limegreen;
    border: 10px solid limegreen;
    width: max-content;
 }

 .game20green {
    display: grid;
    grid-template-columns: repeat(8, 160px);
    grid-template-rows: repeat(5, 160px);
    grid-gap: 10px;
    background-color: limegreen;
    border: 10px solid limegreen;
    width: max-content;
 }




 .game8red {
   display: grid;
   grid-template-columns: repeat(4, 160px);
   grid-template-rows: repeat(4, 160px);
   grid-gap: 10px;
   background-color: red;
   border: 10px solid red;
   width: max-content;
}

.game16red {
   display: grid;
   grid-template-columns: repeat(8, 160px);
   grid-template-rows: repeat(4, 160px);
   grid-gap: 10px;
   background-color: red;
   border: 10px solid red;
   width: max-content;
}

.game20red {
   display: grid;
   grid-template-columns: repeat(8, 160px);
   grid-template-rows: repeat(5, 160px);
   grid-gap: 10px;
   background-color: red;
   border: 10px solid red;
   width: max-content;
}




.solo_igrac{
   background-color: limegreen;
   padding: 10px;
   border-radius: 10px;
   box-shadow: 10px 8px 8px black;
   width: 99.5%;
   margin-left: 5px;
   margin-right: 5px;
   border: 1px solid black;
   color: black;
 }
 .aktivan_igrac{
   background-color: limegreen;
   padding: 10px;
   border-radius: 10px;
   box-shadow: 10px 8px 8px black;
   width: 49%;
   margin-left: 5px;
   margin-right: 5px;
   border: 1px solid black;
   color: black;
 }
 .neaktivan_igrac{
   background-color: red;
   padding: 10px;
   border-radius: 10px;
   box-shadow: 10px 8px 8px black;
   width: 49%;
   margin-left: 5px;
   margin-right: 5px;
   border: 1px solid black;
}


.aktivna_igra{
   pointer-events: auto;
   margin-top: 15px;
   margin-bottom: 15px;
   display: flex;
    justify-content: center;
}
.ne_aktivna_igra{
   pointer-events: none;
   margin-top: 15px;
   margin-bottom: 15px;
   display: flex;
   justify-content: center;
}




.nereseno{
   text-align: center;
   background-color: lightgray;
   //margin: 3px;
   border-radius: 20px;
   padding: 5px;
   font-weight: 800;
   font-size: 20px;
}
.crveni{
   text-align: center;
   background-color: red;
   //margin: 3px;
   border-radius: 20px;
   padding: 5px;
   color: white;
   font-weight: 800;
   font-size: 20px;
}
.plavi{
   text-align: center;
   background-color: blue;
  // margin: 3px;
   border-radius: 20px;
   padding: 5px;
   color: white;
   font-weight: 800;
   font-size: 20px;
}





/* laptop */
@media only screen and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  {
   .game8green {
      display: grid;
      grid-template-columns: repeat(4, 160px);
      grid-template-rows: repeat(4, 160px);
      grid-gap: 10px;
      background-color: limegreen;
      border: 10px solid limegreen;
      width: max-content;
   }
  
   .game16green {
      display: grid;
      grid-template-columns: repeat(8, 160px);
      grid-template-rows: repeat(4, 160px);
      grid-gap: 10px;
      background-color: limegreen;
      border: 10px solid limegreen;
      width: max-content;
   }
  
   .game20green {
      display: grid;
      grid-template-columns: repeat(8, 160px);
      grid-template-rows: repeat(5, 160px);
      grid-gap: 10px;
      background-color: limegreen;
      border: 10px solid limegreen;
      width: max-content;
   }
  
  
  
  
   .game8red {
     display: grid;
     grid-template-columns: repeat(4, 160px);
     grid-template-rows: repeat(4, 160px);
     grid-gap: 10px;
     background-color: red;
     border: 10px solid red;
     width: max-content;
  }
  
  .game16red {
     display: grid;
     grid-template-columns: repeat(8, 160px);
     grid-template-rows: repeat(4, 160px);
     grid-gap: 10px;
     background-color: red;
     border: 10px solid red;
     width: max-content;
  }
  
  .game20red {
     display: grid;
     grid-template-columns: repeat(8, 160px);
     grid-template-rows: repeat(5, 160px);
     grid-gap: 10px;
     background-color: red;
     border: 10px solid red;
     width: max-content;
  }
  

  }















/*telefon*/
@media only screen and (max-width: 600px) {
   .slike{
   width: 86px;
   height: 86px;
   }
   .container{
     // display: block!important;
   }
   .row{
      //width:110%!important;
   }

   .aktivan_igrac{
      width: 47%;
    }
    .neaktivan_igrac{
      width: 47%;
   }

.game8green {
    display: grid;
    grid-template-columns: repeat(4, 82px);
    grid-template-rows: repeat(4, 82px);
    grid-gap: 10px;
    background-color: limegreen;
    border: 10px solid limegreen;
    width: max-content;
 }

 .game16green {
    display: grid;
    grid-template-columns: repeat(4, 84px);
    grid-template-rows: repeat(8, 84px);
    grid-gap: 10px;
    background-color: limegreen;
    border: 10px solid limegreen;
    width: max-content;
 }

 .game20green {
    display: grid;
    grid-template-columns: repeat(8, 86px);
    grid-template-rows: repeat(5, 86px);
    grid-gap: 10px;
    background-color: limegreen;
    border: 10px solid limegreen;
    width: max-content;
 }




 .game8red {
   display: grid;
   grid-template-columns: repeat(4, 82px);
   grid-template-rows: repeat(4, 82px);
   grid-gap: 10px;
   background-color: red;
   border: 10px solid red;
   width: max-content;
}

.game16red {
   display: grid;
   grid-template-columns: repeat(4, 84px);
   grid-template-rows: repeat(8, 84px);
   grid-gap: 10px;
   background-color: red;
   border: 10px solid red;
   width: max-content;
}

.game20red {
   display: grid;
   grid-template-columns: repeat(8, 86px);
   grid-template-rows: repeat(5, 86px);
   grid-gap: 10px;
   background-color: red;
   border: 10px solid red;
   width: max-content;
}


.nereseno{
   text-align: center;
   background-color: lightgray;
   //margin: 3px;
   border-radius: 10px;
   padding: 5px;
   font-weight: 500;
   font-size: 16px;
}
.crveni{
   text-align: center;
   background-color: red;
   //margin: 3px;
   border-radius: 10px;
   padding: 5px;
   color: white;
   font-weight: 500;
   font-size: 16px;
}
.plavi{
   text-align: center;
   background-color: blue;
  // margin: 3px;
   border-radius: 10px;
   padding: 5px;
   color: white;
   font-weight: 500;
   font-size: 16px;
}


}

